<template>
  <div id="AddBlo">
    <div class="form-content">
      <form
        @submit.prevent="
          submit(
            title,
            descrp,
            id_autor,
            id_category,
            id_colaborador,
            dateblog,
            formato,
            file1,
            body,
            tags,
            images,
            url
          )
        "
      >
        <div class="grup-form dflx">
          <div class="boxform">
            <label for="title" class="title">Titulo </label>
            <input
              v-model="title"
              type="text"
              id="title"
              name="title"
              required
            />
          </div>
          <div class="boxform">
            <label for="descrp" class="descrp">Descripción </label>
            <input
              v-model="descrp"
              type="text"
              id="descrp"
              name="descrp"
              required
            />
          </div>

          <div class="boxform">
            <label for="dateblog" class="dateblog">Fecha </label>
            <input
              v-model="dateblog"
              type="date"
              id="dateblog"
              name="dateblog"
              required
            />
          </div>
        </div>
        <div class="grup-form dflx">
          <div class="boxform">
            <label for="url" class="url">Url </label>
            <input
              v-model="url"
              type="text"
              id="url"
              name="url"
              disabled
              required
            />
          </div>
        </div>

        <div class="grup-form dflx">
          <div class="boxform">
            <label>Portada</label>
            <div class="dflx">
              <label class="filelabel" for="uploadimg">
                <span>Seleccionar Archivo</span></label
              >
              <label class="filelabelname tbl_item" for="uploadimg">
                {{ archivo }}</label
              >
            </div>

            <input
              @change="previewFiles2"
              type="file"
              id="uploadimg"
              name="uploadimg"
              class="fileinput"
            />
          </div>
        </div>

        <div class="grup-form dflx">
          <div class="boxform">
            <label for="id_autor" class="id_autor">Autor </label>

            <div class="select-container">
              <input
                type="checkbox"
                name="select-ch2"
                id="select-ch2"
                v-model="active2"
                :true-value="true"
                :false-value="false"
              />
              <label for="select-ch2">
                <div id="select-role" :class="{ rounded: active2 }">
                  {{ autorname }}
                </div>
              </label>
              <div class="options-cont" @click="closeSelect2()">
                <div
                  v-for="(user, key) in usuarios"
                  :key="key"
                  class="option-btn"
                  @click="(id_autor = user.id), (autorname = user.name)"
                >
                  {{ user.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="boxform">
            <label for="id_category" class="id_category">Categoría </label>

            <div class="select-container">
              <input
                type="checkbox"
                name="select-ch3"
                id="select-ch3"
                v-model="active3"
                :true-value="true"
                :false-value="false"
              />
              <label for="select-ch3">
                <div id="select-role" :class="{ rounded: active3 }">
                  {{ catname }}
                </div>
              </label>
              <div class="options-cont" @click="closeSelect3()">
                <div
                  v-for="(cat, key) in categorias"
                  :key="key"
                  class="option-btn"
                  @click="(id_category = cat.id), (catname = cat.name)"
                >
                  {{ cat.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="boxform">
            <label for="id_colaborador" class="id_colaborador"
              >Colaborador
            </label>

            <div class="select-container">
              <input
                type="checkbox"
                name="select-ch4"
                id="select-ch4"
                v-model="active4"
                :true-value="true"
                :false-value="false"
              />
              <label for="select-ch4">
                <div id="select-role" :class="{ rounded: active4 }">
                  {{ colname }}
                </div>
              </label>
              <div class="options-cont" @click="closeSelect4()">
                <div
                  v-for="(col, key) in colaboradores"
                  :key="key"
                  class="option-btn"
                  @click="(id_colaborador = col.id), (colname = col.name)"
                >
                  {{ col.name }}
                </div>
                <div
                  class="option-btn"
                  @click="(id_colaborador = ''), (colname = 'Sin colaborador')"
                >
                  Sin colaborador
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- añadir etiquetas --->
        <hr class="hrhd" />
        <p class="subtitle2">Añadir etiquetas</p>
        <div class="dflx">
          <div class="tbl1">
            <p class="subtitle">Buscar etiquetas</p>

            <div class="tbletiquetas">
              <div class="hdreti">Nombre</div>
              <div class="bodytbl tbl_item">
                <div v-for="(prm, key) in etiquetas" :key="key">
                  <p class="etiqueta cp" @click="pushValue(prm)">
                    {{ prm.name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="buscador dflx">
              <input
                v-model="search_value"
                type="search"
                id="input-search"
                class="form-control"
                :placeholder="'Búsqueda'"
              />
              <div class="icon_buscar">
                <img
                  @click="search(search_value)"
                  src="../../assets/Search.png"
                  alt="icono buscar"
                />
              </div>
            </div>
          </div>

          <div class="tbl2">
            <p class="subtitle">Etiquetas seleccionadas</p>

            <div class="tbletiquetas">
              <div class="hdreti">Nombre</div>
              <div class="bodytbl tbl_item">
                <div v-for="(prm2, key) in tags" :key="key">
                  <div class="dflx">
                    <p class="etiqueta et1">{{ prm2.name }}</p>
                    <p class="etiqueta elim" @click="delValue(prm2)">
                      &#10006;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Añadir bloques -->

        <hr class="hrhd" />
        <p class="subtitle2">Cuerpo de la entrada</p>

        <div class="opciones-bloque">
          <div class="dflx optionsbl">
            <div class="boxform">
              <label for="role" class="role">Tipo de bloque </label>
              <div class="select-container">
                <input
                  type="checkbox"
                  name="select-ch"
                  id="select-ch"
                  v-model="active"
                  :true-value="true"
                  :false-value="false"
                />
                <label for="select-ch">
                  <div id="select-role" :class="{ rounded: active }">
                    {{ tipo }}
                  </div>
                </label>
                <div class="options-cont" @click="closeSelect()">
                  <div class="option-btn" @click="tipo = 'texto'">Texto</div>
                  <div class="option-btn" @click="tipo = 'imagen'">Imagen</div>
                  <div class="option-btn" @click="tipo = 'caja'">Caja</div>
                  <div class="option-btn" @click="tipo = 'frase_destacada'">
                    Frase destacada
                  </div>
                  <div class="option-btn" @click="tipo = 'frase'">Frase</div>
                  <div class="option-btn" @click="tipo = 'mapa'">Mapa</div>
                  <div class="option-btn" @click="tipo = 'video'">Video</div>
                </div>
              </div>
            </div>

            <div @click="guardarBloque(tipo)" class="btn_añadir dflx mtbtn">
              <p>agregar bloque</p>
              <img src="../../assets/add.png" alt="" />
            </div>
          </div>

          <vue-editor
            v-if="tipo == 'texto'"
            id="editor"
            class="quill-editor"
            :editorToolbar="toolbar()"
            v-model="texto"
          >
          </vue-editor>

          <div v-show="tipo == 'video'">
            <!--
                         <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="video" class="video">Insertar iFrame</label>
                            <input v-model="video" type="text" id="video" name="video">
                        </div>
                    </div>
                    -->

            <vue-editor
              id="editor toolbarVideo"
              class="quill-editor"
              :editorToolbar="toolbarVideo()"
              v-model="video"
            >
            </vue-editor>
          </div>

          <div v-if="tipo == 'mapa'">
            <label>Ficha del mapa <span>(opcional)</span></label>
            <vue-editor
              id="editor"
              class="quill-editor"
              :editorToolbar="toolbar()"
              v-model="ficha"
            >
            </vue-editor>

            <div class="grup-form dflx">
              <div class="boxform">
                <label for="mapa" class="mapa">Insertar iFrame del mapa</label>
                <label
                  ><span
                    >Sigue los siguientes pasos para obtener el iFrame > Google
                    Maps > Compartir > Incorporar un mapa > copiar HTML
                  </span></label
                >
                <input v-model="mapa" type="text" id="mapa" name="mapa" />
              </div>
            </div>
          </div>

          <div class="" v-if="tipo == 'imagen'">
            <div class="dflx">
              <label class="filelabel" for="uploadimg2">
                <span>Seleccionar Archivo</span></label
              >
              <label class="filelabelname tbl_item" for="uploadimg2">
                {{ name_imagen }}</label
              >
            </div>

            <div class="mtp2">
              <label>Tamaño de imagen </label>
            </div>
            <div class="options_tam">
              <p
                class="option_tam"
                :class="{ option_tam_act: tam_imagen === 'chica' }"
                @click="tam_imagen = 'chica'"
              >
                Chica
              </p>
              <p
                class="option_tam"
                :class="{ option_tam_act: tam_imagen === 'mediana' }"
                @click="tam_imagen = 'mediana'"
              >
                Mediana
              </p>
              <p
                class="option_tam"
                :class="{ option_tam_act: tam_imagen === 'grande' }"
                @click="tam_imagen = 'grande'"
              >
                Grande
              </p>
            </div>

            <input
              @change="previewFiles"
              type="file"
              id="uploadimg2"
              name="uploadimg2"
              required
              class="fileinput2"
            />
            <div class="mtp">
              <label>Pie de imagen<span>(opcional)</span></label>
              <vue-editor
                id="editor"
                class="quill-editor"
                :editorToolbar="toolbar()"
                v-model="pie_imagen"
              >
              </vue-editor>
            </div>
          </div>

          <div v-if="tipo == 'frase'">
            <div class="grup-form dflx">
              <div class="boxform">
                <label for="frase" class="frase">Frase</label>
                <input v-model="frase" type="text" id="frase" name="frase" />
              </div>
              <div class="boxform">
                <label for="firma" class="firma">Firma</label>
                <input v-model="firma" type="text" id="firma" name="firma" />
              </div>
            </div>
          </div>

          <div v-if="tipo == 'frase_destacada'">
            <div class="grup-form dflx">
              <div class="boxform">
                <label for="frase_destacada" class="frase_destacada"
                  >Frase destacada</label
                >
                <input
                  v-model="frase_destacada"
                  type="text"
                  id="frase_destacada"
                  name="frase_destacada"
                />
              </div>
            </div>
          </div>

          <div v-if="tipo == 'caja'">
            <p class="subtitle">Caja 1</p>

            <div class="dflx btnsgrp">
              <p class="btnopciones" @click="caja1 = 'texto'">Texto</p>
              <p class="btnopciones" @click="caja1 = 'imagen'">Imagen</p>
              <p class="btnopciones" @click="caja1 = 'frase_destacada'">
                Frase destacada
              </p>
              <p class="btnopciones" @click="caja1 = 'frase'">Frase</p>
            </div>

            <vue-editor
              v-if="caja1 == 'texto'"
              id="editor"
              class="quill-editor"
              :editorToolbar="toolbar()"
              v-model="texto"
            >
            </vue-editor>

            <div v-if="caja1 == 'frase_destacada'">
              <div class="grup-form dflx">
                <div class="boxform">
                  <label for="frase_destacada" class="frase_destacada"
                    >Frase destacada</label
                  >
                  <input
                    v-model="frase_destacada"
                    type="text"
                    id="frase_destacada"
                    name="frase_destacada"
                  />
                </div>
              </div>
            </div>
            <div v-if="caja1 == 'frase'">
              <div class="grup-form dflx">
                <div class="boxform">
                  <label for="frase" class="frase">Frase</label>
                  <input v-model="frase" type="text" id="frase" name="frase" />
                </div>
                <div class="boxform">
                  <label for="firma" class="firma">Firma</label>
                  <input v-model="firma" type="text" id="firma" name="firma" />
                </div>
              </div>
            </div>

            <div class="" v-if="caja1 == 'imagen'">
              <div class="dflx">
                <label class="filelabel" for="uploadimg2">
                  <span>Seleccionar Archivo</span></label
                >
                <label class="filelabelname tbl_item" for="uploadimg2">
                  {{ name_imagen }}</label
                >
              </div>

              <div class="mtp2">
                <label>Tamaño de imagen </label>
              </div>
              <div class="options_tam">
                <p
                  class="option_tam"
                  :class="{ option_tam_act: tam_imagen === 'chica' }"
                  @click="tam_imagen = 'chica'"
                >
                  Chica
                </p>
                <p
                  class="option_tam"
                  :class="{ option_tam_act: tam_imagen === 'mediana' }"
                  @click="tam_imagen = 'mediana'"
                >
                  Mediana
                </p>
                <p
                  class="option_tam"
                  :class="{ option_tam_act: tam_imagen === 'grande' }"
                  @click="tam_imagen = 'grande'"
                >
                  Grande
                </p>
              </div>

              <input
                @change="previewFiles"
                type="file"
                id="uploadimg2"
                name="uploadimg2"
                required
                class="fileinput2"
              />
              <div class="mtp">
                <label>Pie de imagen<span>(opcional)</span></label>
                <vue-editor
                  id="editor"
                  class="quill-editor"
                  :editorToolbar="toolbar()"
                  v-model="pie_imagen"
                >
                </vue-editor>
              </div>
            </div>

            <p class="subtitle">Caja 2</p>

            <div class="dflx btnsgrp">
              <p class="btnopciones" @click="caja2 = 'texto'">Texto</p>
              <p class="btnopciones" @click="caja2 = 'imagen'">Imagen</p>
              <p class="btnopciones" @click="caja2 = 'frase_destacada'">
                Frase destacada
              </p>
              <p class="btnopciones" @click="caja2 = 'frase'">Frase</p>
            </div>

            <vue-editor
              v-if="caja2 == 'texto'"
              id="editor"
              class="quill-editor"
              :editorToolbar="toolbar()"
              v-model="texto2"
            >
            </vue-editor>
            <div v-if="caja2 == 'frase_destacada'">
              <div class="grup-form dflx">
                <div class="boxform">
                  <label for="frase_destacada2" class="frase_destacada2"
                    >Frase destacada</label
                  >
                  <input
                    v-model="frase_destacada2"
                    type="text"
                    id="frase_destacada2"
                    name="frase_destacada2"
                  />
                </div>
              </div>
            </div>
            <div v-if="caja2 == 'frase'">
              <div class="grup-form dflx">
                <div class="boxform">
                  <label for="frase2" class="frase2">Frase</label>
                  <input
                    v-model="frase2"
                    type="text"
                    id="frase2"
                    name="frase2"
                  />
                </div>
                <div class="boxform">
                  <label for="firma2" class="firma2">Firma</label>
                  <input
                    v-model="firma2"
                    type="text"
                    id="firma2"
                    name="firma2"
                  />
                </div>
              </div>
            </div>
            <div class="" v-if="caja2 == 'imagen'">
              <div class="dflx">
                <label class="filelabel" for="uploadimg3">
                  <span>Seleccionar Archivo</span></label
                >
                <label class="filelabelname tbl_item" for="uploadimg3">
                  {{ name_imagen2 }}</label
                >
              </div>

              <div class="mtp2">
                <label>Tamaño de imagen </label>
              </div>
              <div class="options_tam">
                <p
                  class="option_tam"
                  :class="{ option_tam_act: tam_imagen2 === 'chica' }"
                  @click="tam_imagen2 = 'chica'"
                >
                  Chica
                </p>
                <p
                  class="option_tam"
                  :class="{ option_tam_act: tam_imagen2 === 'mediana' }"
                  @click="tam_imagen2 = 'mediana'"
                >
                  Mediana
                </p>
                <p
                  class="option_tam"
                  :class="{ option_tam_act: tam_imagen2 === 'grande' }"
                  @click="tam_imagen2 = 'grande'"
                >
                  Grande
                </p>
              </div>

              <input
                @change="previewFiles3"
                type="file"
                id="uploadimg3"
                name="uploadimg3"
                required
                class="fileinput"
              />
              <div class="mtp">
                <label>Pie de imagen<span>(opcional)</span></label>
                <vue-editor
                  id="editor"
                  class="quill-editor"
                  :editorToolbar="toolbar()"
                  v-model="pie_imagen2"
                >
                </vue-editor>
              </div>
            </div>
          </div>
        </div>

        <hr class="hrhd" />
        <p class="subtitle2">Bloques agregados</p>

        <div class="bloques-cont">
          <div class="dflx header-bc">
            <div class="col1">
              <p>ordenar</p>
            </div>
            <div class="col2">
              <p>Tipo</p>
            </div>
            <div class="col3 cl3t">
              <p>Contenido</p>
            </div>
            <div class="col4">
              <p>Eliminar</p>
            </div>
          </div>
          <div v-for="(bloq, key) in body" :key="key" class="bloq-item dflx">
            <div class="col1">
              <h2 v-if="key != 0" @click="UpItem(bloq, key)" class="cp">
                &#9650;
              </h2>
              <h2
                v-if="key < body.length - 1"
                @click="DownItem(bloq, key)"
                class="cp"
              >
                &#9660;
              </h2>
            </div>
            <div class="col2">
              <p v-if="bloq.tipo != 'frase_destacada'">{{ bloq.tipo }}</p>
              <p v-else>
                frase <br />
                destacada
              </p>
            </div>
            <div class="col3">
              <!-- contenido del bloque  -->
              <div v-if="bloq.tipo == 'texto'">
                <div v-if="posicion !== key" v-html="bloq.texto"></div>
                <vue-editor
                  v-else
                  id="editor"
                  class="quill-editor ajus-qull"
                  :editorToolbar="toolbar()"
                  v-model="body[posicion].texto"
                >
                </vue-editor>
              </div>

              <div v-if="bloq.tipo == 'video'" v-html="bloq.video"></div>
              <div v-if="bloq.tipo == 'mapa'">
                <div v-if="posicion !== key">
                  <p v-if="bloq.ficha" v-html="bloq.ficha"></p>
                  <p v-html="bloq.mapa"></p>
                </div>
                <div v-else>
                  <label>Ficha del mapa <span>(opcional)</span></label>
                  <vue-editor
                    id="editor"
                    class="quill-editor ajus-qull"
                    :editorToolbar="toolbar()"
                    v-model="body[posicion].ficha"
                  >
                  </vue-editor>
                  <label for="mapa" class="mapa"
                    >Insertar iFrame del mapa</label
                  >
                  <label
                    ><span
                      >Sigue los siguientes pasos para obtener el iFrame >
                      Google Maps > Compartir > Incorporar un mapa > copiar HTML
                    </span></label
                  >
                  <textarea
                    placeholder="Inserta el iframe del mapa"
                    v-model="body[posicion].mapa"
                    type="text"
                    class="input_editar"
                  >
                  </textarea>
                </div>
              </div>
              <div v-if="bloq.tipo == 'frase'">
                <p v-if="posicion !== key">
                  "{{ bloq.frase.texto }}" <br />
                  - {{ bloq.frase.firma }}
                </p>
                <div v-else>
                  <textarea
                    placeholder="Escribe el texto de la frase"
                    v-model="body[posicion].frase.texto"
                    type="text"
                    class="input_editar"
                  >
                  </textarea>
                  <textarea
                    placeholder="Escribe la firma de la frase"
                    v-model="body[posicion].frase.firma"
                    type="text"
                    class="input_editar"
                  >
                  </textarea>
                </div>
              </div>
              <div v-if="bloq.tipo == 'frase_destacada'">
                <p v-if="posicion !== key">
                  <strong>"{{ bloq.frase_destacada }}"</strong>
                </p>
                <textarea
                  v-else
                  v-model="body[posicion].frase_destacada"
                  type="text"
                  class="input_editar"
                  placeholder="Escribe la frase destacada"
                >
                </textarea>
              </div>
              <div v-if="bloq.tipo == 'imagen'">
                <img
                  :src="srcImg(bloq.imagen)"
                  alt="imagen bloque"
                  :class="{
                    img_chica: bloq.tam_imagen === 'chica',
                    img_mediana: bloq.tam_imagen === 'mediana',
                    img_grande: bloq.tam_imagen === 'grande',
                  }"
                />
                <p v-if="posicion !== key" v-html="bloq.pie_imagen"></p>
                <div v-else>
                  <p>Escribe el pie de la imagen:</p>
                  <vue-editor
                    id="editor"
                    class="quill-editor ajus-qull"
                    :editorToolbar="toolbar()"
                    v-model="body[posicion].pie_imagen"
                  >
                  </vue-editor>
                </div>
              </div>
              <div v-if="bloq.tipo == 'caja'" class="dflx">
                <div class="caja1">
                  <div v-if="bloq.caja[0].tipo == 'texto'">
                    <p v-if="posicion !== key" v-html="bloq.caja[0].texto"></p>
                    <vue-editor
                      v-else
                      id="editor"
                      class="quill-editor ajus-qull2"
                      :editorToolbar="toolbar()"
                      v-model="body[posicion].caja[0].texto"
                    >
                    </vue-editor>
                  </div>

                  <div v-if="bloq.caja[0].tipo == 'imagen'">
                    <img
                      :src="srcImg(bloq.caja[0].imagen)"
                      alt="imagen bloque"
                      :class="{
                        img_chica: bloq.caja[0].tam_imagen === 'chica',
                        img_mediana: bloq.caja[0].tam_imagen === 'mediana',
                        img_grande: bloq.caja[0].tam_imagen === 'grande',
                      }"
                    />
                    <p
                      v-if="posicion !== key"
                      v-html="bloq.caja[0].pie_imagen"
                    ></p>
                    <div v-else>
                      <p>Escribe el pie de la imagen:</p>
                      <vue-editor
                        id="editor"
                        class="quill-editor ajus-qull2"
                        :editorToolbar="toolbar()"
                        v-model="body[posicion].caja[0].pie_imagen"
                      >
                      </vue-editor>
                    </div>
                  </div>

                  <div v-if="bloq.caja[0].tipo == 'frase'">
                    <p v-if="posicion !== key">
                      "{{ bloq.caja[0].frase.texto }}" <br />
                      - {{ bloq.caja[0].frase.firma }}
                    </p>

                    <div v-else>
                      <textarea
                        placeholder="Escribe el texto de la frase"
                        v-model="body[posicion].caja[0].frase.texto"
                        type="text"
                        class="input_editar"
                      >
                      </textarea>
                      <textarea
                        placeholder="Escribe la firma de la frase"
                        v-model="body[posicion].caja[0].frase.firma"
                        type="text"
                        class="input_editar"
                      >
                      </textarea>
                    </div>
                  </div>
                  <div v-if="bloq.caja[0].tipo == 'frase_destacada'">
                    <p v-if="posicion !== key">
                      <strong>"{{ bloq.caja[0].frase_destacada }}"</strong>
                    </p>
                    <textarea
                      v-else
                      placeholder="Escribe el texto de la frase"
                      v-model="body[posicion].caja[0].frase_destacada"
                      type="text"
                      class="input_editar"
                    >
                    </textarea>
                  </div>
                </div>
                <h2 class="cp" @click="CambiarOrdenCaja(bloq, key)">
                  &#11020;
                </h2>
                <div class="caja2">
                  <div v-if="bloq.caja[1].tipo == 'texto'">
                    <p v-if="posicion !== key" v-html="bloq.caja[1].texto"></p>
                    <vue-editor
                      v-else
                      id="editor"
                      class="quill-editor ajus-qull2"
                      :editorToolbar="toolbar()"
                      v-model="body[posicion].caja[1].texto"
                    >
                    </vue-editor>
                  </div>
                  <div v-if="bloq.caja[1].tipo == 'imagen'">
                    <img
                      :src="srcImg(bloq.caja[1].imagen)"
                      alt="imagen bloque"
                      :class="{
                        img_chica: bloq.caja[1].tam_imagen === 'chica',
                        img_mediana: bloq.caja[1].tam_imagen === 'mediana',
                        img_grande: bloq.caja[1].tam_imagen === 'grande',
                      }"
                    />
                    <p
                      v-if="posicion !== key"
                      v-html="bloq.caja[1].pie_imagen"
                    ></p>
                    <div v-else>
                      <p>Escribe el pie de la imagen:</p>
                      <vue-editor
                        id="editor"
                        class="quill-editor ajus-qull2"
                        :editorToolbar="toolbar()"
                        v-model="body[posicion].caja[1].pie_imagen"
                      >
                      </vue-editor>
                    </div>
                  </div>

                  <div v-if="bloq.caja[1].tipo == 'frase'">
                    <p v-if="posicion !== key">
                      "{{ bloq.caja[1].frase.texto }}" <br />
                      - {{ bloq.caja[1].frase.firma }}
                    </p>
                    <div v-else>
                      <textarea
                        placeholder="Escribe el texto de la frase"
                        v-model="body[posicion].caja[1].frase.texto"
                        type="text"
                        class="input_editar"
                      >
                      </textarea>
                      <textarea
                        placeholder="Escribe la firma de la frase"
                        v-model="body[posicion].caja[1].frase.firma"
                        type="text"
                        class="input_editar"
                      >
                      </textarea>
                    </div>
                  </div>
                  <div v-if="bloq.caja[1].tipo == 'frase_destacada'">
                    <p v-if="posicion !== key">
                      <strong>"{{ bloq.caja[1].frase_destacada }}"</strong>
                    </p>
                    <textarea
                      v-else
                      placeholder="Escribe el texto de la frase"
                      v-model="body[posicion].caja[0].frase_destacada"
                      type="text"
                      class="input_editar"
                    >
                    </textarea>
                  </div>
                </div>
              </div>

              <div v-if="bloq.tipo != 'video'">
                <p
                  v-if="posicion !== key"
                  class="btnEditar"
                  @click="(posicion = key), (editar = true)"
                >
                  Editar
                </p>
                <p
                  v-if="editar && posicion === key"
                  class="btnEditar"
                  @click="(posicion = null), (editar = false)"
                >
                  Aceptar
                </p>
              </div>
            </div>
            <div class="col4">
              <h2 class="cp" @click="DelItem(bloq, key)">&#10006;</h2>
            </div>
          </div>
          <p class="snu" v-if="body.length == 0">
            Sin contenido agregado al cuerpo del blog
          </p>
        </div>

        <div v-if="status != ''" class="status_messages">
          <div v-if="status == 'success'" class="msg msg_success">
            <p>{{ message }}</p>
          </div>
          <div v-if="status == 'error'" class="msg msg_error">
            <p>{{ message }}</p>
          </div>
        </div>

        <div class="dflx">
          <p @click="wait()" class="btnRegresar">Regresar</p>
          <button class="alta" type="submit">Guardar</button>
        </div>
      </form>

      <div v-if="viewModal" class="modal_view">
        <div id="modal_add">
          <div class="body dflx">
            <h3>Entrada agregada</h3>
            <img src="../../assets/add.png" alt="icono alerta" />
          </div>

          <div class="modal_add_btns dflx">
            <p @click="Add()" class="otro">Aceptar</p>
            <p @click="toEdit()" class="editar">Editar</p>
            <p @click="back()" class="inicio">Inicio</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { mapActions } from "vuex";
let { url, createURL } = require("../../global/url");

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      createURL,
      urlpath: url(),
      title: "",
      descrp: "",
      url: "",
      id_autor: "",
      autorname: "",
      id_category: "",
      catname: "",
      id_colaborador: "",
      file1: null,
      archivo: "No se eligió archivo",
      colname: "",
      dateblog: "",
      formato: "",
      tags: [], //etiquetas agregadas
      images: [], //imagenes agregadas

      //bloques
      body: [],
      tipo: "texto",
      caja1: "texto",
      caja2: "texto",
      image: null,
      name_imagen: "",
      pie_imagen: "",
      tam_imagen: "grande",
      frase_destacada: "",
      video: "",
      mapa: "",
      ficha: "",
      frase: "",
      firma: "",
      texto: "",

      image2: null,
      name_imagen2: "",
      pie_imagen2: "",
      tam_imagen2: "grande",
      frase_destacada2: "",
      video2: "",
      frase2: "",
      firma2: "",
      texto2: "",

      editorOption: {
        modules: {},

        theme: "snow",
      },

      status: "",
      message: "",

      //selects

      active: false,
      active2: false,
      active3: false,
      active4: false,
      active5: false,
      active6: false,
      active7: false,

      viewModal: false,

      search_value: "",
      updsearchcli: "",
      posicion: null,
      editar: false,
    };
  },
  watch: {
    title(value) {
      this.url = this.createURL(value);
    },
  },
  created() {
    this.getAllInfoTg("tag");
    this.getAllInfoCat("category");
    this.getAllInfoClb("colaboradores");
    this.getAllInfoUsr("usuarios");
  },
  computed: {
    etiquetas() {
      const etiquetas = this.$store.getters["tag/data"];
      if (!Array.isArray(etiquetas)) return [];
      return etiquetas;
    },
    colaboradores() {
      const colaboradores = this.$store.getters["colaboradores/data"];
      if (!Array.isArray(colaboradores)) return [];
      return colaboradores.filter((item) => item.statusCol === "VISIBLE");
    },
    usuarios() {
      const usuarios = this.$store.getters["usuarios/data"];
      if (!Array.isArray(usuarios)) return [];
      return usuarios.filter((item) => item.role !== "legrafica");
    },
    categorias() {
      const categorias = this.$store.getters["category/data"];
      if (!Array.isArray(categorias)) return [];
      return categorias;
    },
  },
  methods: {
    ...mapActions("tag", ["getAllInfoTg"]),
    ...mapActions("category", ["getAllInfoCat"]),
    ...mapActions("usuarios", ["getAllInfoUsr"]),
    ...mapActions("colaboradores", ["getAllInfoClb"]),
    ...mapActions("blog", ["setAddedBlo"]),
    ...mapActions("blog", ["setHistoryOptionBlo"]),
    includesItem(search, name) {
      if (search === "" || !search || !name) {
        return true;
      }
      name = name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      search = search.toLowerCase();
      return name.includes(search);
    },

    UpItem: function (item, key) {
      let bloques = this.body;

      let anterior = key - 1;
      let actual = key;
      let item_actual = item;
      let item_anterior = bloques[key - 1];

      let array = [];

      for (var i = 0; i < bloques.length; i++) {
        if (i == actual) {
          array.push(item_anterior);
        } else if (i == anterior) {
          array.push(item_actual);
        } else {
          array.push(bloques[i]);
        }
      }

      this.body = array;
    },
    DownItem: function (item, key) {
      let bloques = this.body;

      let siguiente = key + 1;
      let actual = key;
      let item_actual = item;
      let item_siguiente = bloques[key + 1];

      let array = [];

      for (var i = 0; i < bloques.length; i++) {
        if (i == actual) {
          array.push(item_siguiente);
        } else if (i == siguiente) {
          array.push(item_actual);
        } else {
          array.push(bloques[i]);
        }
      }

      this.body = array;
    },
    DelItem: async function (bloq, key) {
      let bloques = this.body;

      let array = [];

      for (var i = 0; i < bloques.length; i++) {
        if (i != key) {
          array.push(bloques[i]);
        }
      }

      this.body = array;

      //! eliminar la imagen si es de tipo imagen.
      if (bloq.tipo == "imagen") {
        let result = await this.$store.dispatch("blog/deleteItemBlo", {
          option: "blogimage",
          id: bloq.imagen,
        });
      }
      if (bloq.tipo == "caja") {
        if (bloq.caja[0].tipo == "imagen") {
          let result = await this.$store.dispatch("blog/deleteItemBlo", {
            option: "blogimage",
            id: bloq.caja[0].imagen,
          });
        }

        if (bloq.caja[1].tipo == "imagen") {
          let result = await this.$store.dispatch("blog/deleteItemBlo", {
            option: "blogimage",
            id: bloq.caja[1].imagen,
          });
        }
      }
    },
    CambiarOrdenCaja: function (bloq, key) {
      let bloques = this.body;

      let array = [];

      for (var i = 0; i < bloques.length; i++) {
        if (i != key) {
          array.push(bloques[i]);
        } else {
          let model = { caja: [bloq.caja[1], bloq.caja[0]], tipo: "caja" };
          array.push(model);
        }
      }

      this.body = array;
    },
    search: async function (value) {
      let option = "tag";

      let payload = {
        option: option,
        search: value,
      };
      if (value != "") {
        this.$store.dispatch("tag/searchTg", payload);
      } else {
        this.getAllInfoTg("tag");
      }
    },
    pushValue(param) {
      let array = [];
      let encontrado = false;
      for (const item of this.tags) {
        item == param ? (encontrado = true) : encontrado;
        array.push(item);
      }
      if (encontrado) {
        this.status = "error";
        this.message = "Etiqueta ya agregada";
        this.delStatus();
      } else {
        array.push(param);
      }

      this.tags = array;
    },

    delValue(param) {
      let array = [];

      for (const item of this.tags) {
        item != param ? array.push(item) : array;
      }

      this.tags = array;
    },

    closeSelect() {
      this.active = false;
      document.getElementById("select-ch").checked = false;
    },
    closeSelect2() {
      this.active2 = false;
      document.getElementById("select-ch2").checked = false;
    },
    closeSelect3() {
      this.active3 = false;
      document.getElementById("select-ch3").checked = false;
    },
    closeSelect4() {
      this.active4 = false;
      document.getElementById("select-ch4").checked = false;
    },
    closeSelect5() {
      this.active5 = false;
      document.getElementById("select-ch5").checked = false;
    },
    closeSelect6() {
      this.active6 = false;
      document.getElementById("select-ch6").checked = false;
    },
    closeSelect7() {
      this.active7 = false;
      document.getElementById("select-ch7").checked = false;
    },

    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (
      title,
      descrp,
      id_autor,
      id_category,
      id_colaborador,
      dateblog,
      formato,
      file1,
      body,
      tags,
      images,
      url
    ) {
      this.status = "";
      this.message = "";

      if (!file1 || file1 === null) {
        this.status = "error";
        this.message = "Portada no adjunta";
        this.delStatus();
        return;
      }

      if (!id_autor) {
        this.status = "error";
        this.message = "Autor no seleccionado";
        this.delStatus();
        return;
      }

      if (!id_category) {
        this.status = "error";
        this.message = "Categoria no seleccionada";
        this.delStatus();
        return;
      }

      formato = "F1";

      body = JSON.stringify(body);
      tags = JSON.stringify(tags);
      images = JSON.stringify(images);

      var data = new FormData();
      data.append("title", title);
      data.append("url", url);
      data.append("descrp", descrp);
      data.append("id_autor", id_autor);
      data.append("id_category", id_category);
      data.append("id_colaborador", id_colaborador);
      data.append("dateblog", dateblog);
      data.append("formato", formato);
      data.append("body", body);
      data.append("tags", tags);
      data.append("imagenes", images);
      data.append("image", file1);
      data.append("_method", "POST");

      let result = await this.$store.dispatch("blog/addItemBlo", {
        option: "blog",
        item: data,
      });

      if (result.status == "error") {
        this.status = "error";
        this.message = result.message;
      } else {
        // success
        this.setAddedBlo(result.added);
        this.showModal();
      }
    },

    delStatus: function () {
      setTimeout(() => this.delMsgs(), 2000);
    },
    closeSelect() {
      this.active = false;
      document.getElementById("select-ch").checked = false;
    },

    showModal: function () {
      this.viewModal = true;
    },
    closeModal: function () {
      this.viewModal = false;
      this.setAddedBlo("");
      this.setHistoryOptionBlo("Default");
    },
    Add: function () {
      this.status = "";
      this.message = "";
      this.title = "";
      this.url = "";
      this.descrp = "";
      this.id_autor = "";
      this.autorname = "";
      this.id_category = "";
      this.catname = "";
      this.id_colaborador = "";
      this.file1 = null;
      this.archivo = "No se eligió archivo";
      this.colname = "";
      this.dateblog = "";
      this.formato = "";
      this.tags = [];
      this.images = [];

      //!resetear bloques
      this.body = [];
      this.tipo = "texto";
      this.caja1 = "texto";
      this.caja2 = "texto";
      this.image = null;
      this.name_imagen = "";
      this.frase_destacada = "";
      this.video = "";
      this.frase = "";
      this.firma = "";
      this.texto = "";

      this.image2 = null;
      this.name_imagen2 = "";
      this.frase_destacada2 = "";
      this.video2 = "";
      this.frase2 = "";
      this.firma2 = "";
      this.texto2 = "";

      this.viewModal = false;
      this.setAddedBlo("");
    },
    toEdit: function () {
      this.setHistoryOptionBlo("Edit");
      this.viewModal = false;
    },
    back: function () {
      setTimeout(() => this.$router.go(), 200);
    },

    // bloques
    async guardarBloque(tipo) {
      if (tipo == "texto") {
        if (this.texto == undefined || this.texto == "" || this.texto == null) {
          this.status = "error";
          this.message = "Texto vacio.";
          this.delStatus();
        } else {
          let array = [];
          for (const bloque of this.body) {
            array.push(bloque);
          }

          let model = { texto: this.texto, tipo: tipo };
          array.push(model);
          this.body = array;
          this.texto = "";
        }
      } else if (tipo == "video") {
        if (this.video == undefined || this.video == "" || this.video == null) {
          this.status = "error";
          this.message = "Agrega un video.";
          this.delStatus();
        } else {
          let array = [];
          for (const bloque of this.body) {
            array.push(bloque);
          }

          let model = { video: this.video, tipo: tipo };
          array.push(model);
          this.body = array;
          this.video = "";
        }
      } else if (tipo == "mapa") {
        if (!this.mapa) {
          this.status = "error";
          this.message = "Agrega un iframe de mapa valido.";
          this.delStatus();
        } else {
          let array = [];
          for (const bloque of this.body) {
            array.push(bloque);
          }

          let model = { mapa: this.mapa, ficha: this.ficha, tipo: tipo };
          array.push(model);
          this.body = array;
          this.mapa = "";
          this.ficha = "";
        }
      } else if (tipo == "imagen") {
        if (
          this.name_imagen == undefined ||
          this.name_imagen == "" ||
          this.name_imagen == null
        ) {
          this.status = "error";
          this.message = "No has subido una imagen.";
          this.image = null;
          this.name_imagen = "";
          this.delStatus();
        } else {
          let array = [];
          for (const bloque of this.body) {
            array.push(bloque);
          }

          //* subir imagen al backend

          var data = new FormData();

          data.append("image", this.image);
          data.append("_method", "POST");

          let result = await this.$store.dispatch("blog/addItemBlo", {
            option: "blog-image",
            item: data,
          });

          if (result.status == "success") {
            let array2 = [];
            for (const img of this.images) {
              array2.push(img);
            }
            array2.push(result.fileName);
            this.images = array2;

            let model = {
              imagen: result.fileName,
              pie_imagen: this.pie_imagen,
              tam_imagen: this.tam_imagen,
              tipo: tipo,
            };
            array.push(model);
            this.body = array;
          } else {
            this.status = "error";
            this.message = result.message;
            //enviar a borrar la imagen
          }

          this.image = null;
          this.name_imagen = "";
          this.pie_imagen = "";
          this.tam_imagen = "grande";
        }
      } else if (tipo == "frase") {
        if (
          this.frase == undefined ||
          this.frase == "" ||
          this.frase == null ||
          this.firma == null ||
          this.firma == null ||
          this.firma == null
        ) {
          this.status = "error";
          this.message = "Frase o firma vacia.";
          this.delStatus();
        } else {
          let array = [];
          for (const bloque of this.body) {
            array.push(bloque);
          }

          let model = {
            frase: { texto: this.frase, firma: this.firma },
            tipo: tipo,
          };
          array.push(model);
          this.body = array;
          this.frase = "";
          this.firma = "";
        }
      } else if (tipo == "frase_destacada") {
        if (
          this.frase_destacada == undefined ||
          this.frase_destacada == "" ||
          this.frase_destacada == null
        ) {
          this.status = "error";
          this.message = "Frase destacada vacio.";
          this.delStatus();
        } else {
          let array = [];
          for (const bloque of this.body) {
            array.push(bloque);
          }

          let model = { frase_destacada: this.frase_destacada, tipo: tipo };
          array.push(model);
          this.body = array;
          this.frase_destacada = "";
        }
      } else if (tipo == "caja") {
        let item1;
        let item2;
        let statusC1 = "success";
        let statusC2 = "success";
        if (this.caja1 == "texto") {
          if (
            this.texto == undefined ||
            this.texto == "" ||
            this.texto == null
          ) {
            this.status = "error";
            this.message = "Texto vacio.";
            statusC1 = "error";
            this.delStatus();
          } else {
            item1 = { texto: this.texto, tipo: this.caja1 };
          }
        } else if (this.caja1 == "frase_destacada") {
          if (
            this.frase_destacada == undefined ||
            this.frase_destacada == "" ||
            this.frase_destacada == null
          ) {
            this.status = "error";
            this.message = "Frase destacada vacia.";
            statusC1 = "error";
            this.delStatus();
          } else {
            item1 = { frase_destacada: this.frase_destacada, tipo: this.caja1 };
          }
        } else if (this.caja1 == "frase") {
          if (
            this.frase == undefined ||
            this.frase == "" ||
            this.frase == null ||
            this.firma == null ||
            this.firma == null ||
            this.firma == null
          ) {
            this.status = "error";
            this.message = "Frase o firma vacia.";
            statusC1 = "error";
            this.delStatus();
          } else {
            item1 = {
              frase: { texto: this.frase, firma: this.firma },
              tipo: this.caja1,
            };
          }
        } else if (this.caja1 == "imagen") {
          if (
            this.name_imagen == undefined ||
            this.name_imagen == "" ||
            this.name_imagen == null
          ) {
            this.status = "error";
            this.message = "No has subido una imagen.";
            statusC1 = "error";
            this.delStatus();
          } else {
            //* subir imagen al backend

            var data = new FormData();

            data.append("image", this.image);
            data.append("_method", "POST");

            let result = await this.$store.dispatch("blog/addItemBlo", {
              option: "blog-image",
              item: data,
            });

            if (result.status == "success") {
              let array = [];
              for (const img of this.images) {
                array.push(img);
              }
              array.push(result.fileName);
              this.images = array;
              item1 = {
                imagen: result.fileName,
                pie_imagen: this.pie_imagen,
                tam_imagen: this.tam_imagen,
                tipo: this.caja1,
              };
            } else {
              this.status = "error";
              this.message = result.message;
              statusC1 = "error";
              //enviar a borrar la imagen
            }
          }
        } else {
          statusC1 = "error";
        }

        if (this.caja2 == "texto") {
          if (
            this.texto2 == undefined ||
            this.texto2 == "" ||
            this.texto2 == null
          ) {
            this.status = "error";
            this.message = "Texto vacio.";
            statusC2 = "error";
            this.delStatus();
          } else {
            item2 = { texto: this.texto2, tipo: this.caja2 };
          }
        } else if (this.caja2 == "frase_destacada") {
          if (
            this.frase_destacada2 == undefined ||
            this.frase_destacada2 == "" ||
            this.frase_destacada2 == null
          ) {
            this.status = "error";
            this.message = "Frase destacada vacia.";
            statusC2 = "error";
            this.delStatus();
          } else {
            item2 = {
              frase_destacada: this.frase_destacada2,
              tipo: this.caja2,
            };
          }
        } else if (this.caja2 == "frase") {
          if (
            this.frase2 == undefined ||
            this.frase2 == "" ||
            this.frase2 == null ||
            this.firma2 == null ||
            this.firma2 == null ||
            this.firma2 == null
          ) {
            this.status = "error";
            this.message = "Frase o firma vacia.";
            statusC2 = "error";
            this.delStatus();
          } else {
            item2 = {
              frase: { texto: this.frase2, firma: this.firma2 },
              tipo: this.caja2,
            };
          }
        } else if (this.caja2 == "imagen") {
          if (
            this.name_imagen2 == undefined ||
            this.name_imagen2 == "" ||
            this.name_imagen2 == null
          ) {
            this.status = "error";
            this.message = "No has subido una imagen.";
            statusC2 = "error";
            this.delStatus();
          } else {
            //* subir imagen al backend

            var data = new FormData();

            data.append("image", this.image2);
            data.append("_method", "POST");

            let result = await this.$store.dispatch("blog/addItemBlo", {
              option: "blog-image",
              item: data,
            });

            if (result.status == "success") {
              let array = [];
              for (const img of this.images) {
                array.push(img);
              }
              array.push(result.fileName);
              this.images = array;

              item2 = {
                imagen: result.fileName,
                pie_imagen: this.pie_imagen2,
                tam_imagen: this.tam_imagen2,
                tipo: this.caja2,
              };
            } else {
              this.status = "error";
              this.message = result.message;
              statusC2 = "error";
              //enviar a borrar la imagen
            }
          }
        } else {
          statusC2 = "error";
        }

        if (statusC1 == "success" && statusC2 == "success") {
          let model = { caja: [item1, item2], tipo: tipo };

          let array = [];
          for (const bloque of this.body) {
            array.push(bloque);
          }

          array.push(model);
          this.body = array;
          this.texto = "";
          this.texto2 = "";
          this.frase_destacada = "";
          this.frase_destacada2 = "";
          this.frase = "";
          this.frase2 = "";
          this.firma = "";
          this.firma2 = "";
          this.image = "";
          this.name_imagen = "";
          this.image2 = "";
          this.name_imagen2 = "";
          this.pie_imagen = "";
          this.pie_imagen2 = "";
          this.tam_imagen = "grande";
          this.tam_imagen2 = "grande";
        } else {
          this.status = "error";
          this.message = "Una de las cajas esta vacia.";

          this.delStatus();
        }
      } else {
        this.status = "error";
        this.message = "Selecciona un tipo de bloque.";
        this.delStatus();
      }
    },

    delStatus: function () {
      setTimeout(() => this.delMsgs(), 2000);
    },
    delMsgs: function () {
      this.status = "";
      this.message = "";
    },

    toolbar: function () {
      return [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // [ 'link','image' ,'video' ],          // add's image support
        ["link"], // add's image support
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
      ];
    },
    toolbarVideo: function () {
      return [["video"]];
    },
    previewFiles(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.image = files[0];

      this.name_imagen = this.image.name;
    },

    previewFiles3(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.image2 = files[0];

      this.name_imagen2 = this.image2.name;
    },

    previewFiles2(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file1 = files[0];
      this.archivo = this.file1.name;
    },

    srcImg: function (img) {
      let src = `${this.urlpath}/blog-img/${img}`;

      //this.status_img(img)

      return src;
    },
    status_img: async function (img) {
      let payload = {
        option: "colaboradores",
        image: img,
      };
      let response = "";

      try {
        response = await this.$store.dispatch("main/getStatusImage", payload);
        if (response.data.status == "error") {
          this.statusimg = "error";
        } else {
          this.statusimg = "success";
        }
      } catch (err) {
        this.statusimg = "error";
      }
    },
  },
};
</script>
<style scoped>
.opciones-bloque {
  min-height: 20vw;
}

/* QUILL EDITOR */
.quill-editor,
.quill-editor-imagen,
.quill-editor-video {
  margin-bottom: 3vw;
  margin-top: 1vw;
}
.quill-editor {
  width: 53.65vw;
}
.quill-editor-imagen,
.quill-editor-video {
  width: 27.65vw;
}

.tbl1,
.tbl2 {
  width: 20vw;

  margin-right: 1vw;
}

p.et1 {
  width: 15vw;
  cursor: default;
}
p.elim {
  cursor: pointer;
  color: var(--color-3) !important;
}

.bodytbl {
  height: 10vw;
  overflow: auto;
  padding: 1vw;
  background: var(--color-2);
}
p.etiqueta {
  color: var(--color-5);

  font-size: 0.6vw;
}

.hdreti {
  background: var(--color-1);
  padding: 0.5vw 1vw;
  color: var(--color-5);
  font-weight: 600;
  font-size: 0.8vw;
}

label span {
  font-weight: 400;
}
#AddBlo {
  margin-bottom: 5vw;
}
#AddBlo label {
  color: var(--color-2);
  font-weight: 700;
  font-size: 0.7291666666666666vw;
  margin-bottom: 0.3vw;
}

#AddBlo input,
#AddBlo select {
  height: 2.5vw;
  width: 13.716666666666668vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
  padding-right: 1vw;
  margin-right: 2vw;
}

#select-role {
  height: 2.5vw;
  width: 14.716667vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
  display: flex;
  align-items: center;
  background-image: url("../../assets/blueflecha.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 12.6vw;
}
#select-role.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw;
  border-left: 0.052083vw solid var(--color-2);
  border-right: 0.052083vw solid var(--color-2);
  border-top: 0.052083vw solid var(--color-2);
  border-bottom: 0;
}
#select-ch:checked ~ .options-cont,
#select-ch2:checked ~ .options-cont,
#select-ch3:checked ~ .options-cont,
#select-ch4:checked ~ .options-cont,
#select-ch5:checked ~ .options-cont,
#select-ch6:checked ~ .options-cont,
#select-ch7:checked ~ .options-cont {
  display: flex;
  position: absolute;
  padding: 0.573vw 1.25vw;
  flex-direction: column;
  background-color: white;
  width: 13.32vw;
  border-left: 0.052083vw solid var(--color-2);
  border-right: 0.052083vw solid var(--color-2);
  border-top: 0;
  border-bottom: 0.052083vw solid var(--color-2);
}

.buscador input {
  width: 15.312500000000002vw;
  border: none !important;
  border-radius: none !important;
  height: 1.5625vw !important;
  color: #8d8d8d;
}
.mtbtn {
  margin-left: 16vw !important;
  margin-top: 1.4vw;
  margin-left: 16vw;
}

.optionsbl {
  margin-top: 1vw;
}

.col1 {
  width: 5vw;
  text-align: center;
}
.col2 {
  width: 8vw;
  text-align: center;
}
.col3 {
  overflow: auto;
  width: 53vw;
}
.cl3t {
  text-align: center;
}

.col3 p {
  padding-left: 1vw;
}
.col4 {
  width: 5vw;
  text-align: center;
}

.header-bc p {
  margin-right: 1px;
  color: aliceblue;
  background: var(--color-2);
  margin-bottom: 0vw;
  font-weight: 600;
  padding: 0.5vw 0vw;
}

.bloq-item {
  border-bottom: 0.2vw solid var(--color-4);
  color: var(--color-4);
}

.bloq-item h2:hover {
  color: var(--color-1);
}

.bloques-cont {
  width: 71vw;
  margin-bottom: 5vw;
}

h2.cp {
  cursor: pointer;
}
.caja1,
.caja2 {
  width: 50%;
}

.col3 img {
  width: 72%;
  padding: 0.5vw 0vw;
}

.col3 iframe {
  padding: 0.5vw 3.5vw;
}

.btnEditar {
  background-color: #f8b841;
  width: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0.3vw 0vw !important;
  margin-left: 2vw;
  margin-top: 1vw;
  border-radius: 2vw;
  font-size: 0.8vw;
  cursor: pointer;
}

.input_editar {
  border-radius: 0vw !important;
  border: 1px solid #ccc;
  width: 90% !important;
  font-family: var(--Poppins);
  height: auto;
  box-sizing: border-box;
  padding: 0.5vw;
  margin-top: 1vw;
  margin-left: 1vw;
}
</style>
